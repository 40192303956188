import React, {useState, useEffect} from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import SlideRightOnAppear from '../components/SlideRightOnAppear'
import SlideLeftOnAppear from '../components/SlideLeftOnAppear'
import SlideBottomOnAppear from '../components/SlideBottomOnAppear'
import FocusInExpandFwd from '../components/FocusInExpandFwd'
import { Link } from 'gatsby';
import intro from '../assets/images/intro.jpg';

const IndexPage = () => {

  const [imgLoaded, setimgLoaded] = useState(false)
  useEffect(() => {
    if(!imgLoaded && typeof window !== undefined)
    {
      setimgLoaded(true)
    }
    return () => {
      
    }
  }, [])

  return (
    <>
        <Helmet
          title="Le Relais de l'avance - Traiteur pour mariage et restaurant Hautes Alpes"
          meta={[
            { name: 'description', content: "Le relais de l'avance est un Bar - restaurant - traiteur pour mariage avec le concept resto'roul situé à  Saint-Étienne-le-Laus dans les Hautes Alpes 05" },
            { name: 'og:title', content: "Relais de l'avance Restaurant traiteur mariage Hautes Alpes 05"},
            { name: 'og:description', content: "Le relais de l'avance est un Bar - restaurant - traiteur pour mariage avec le concept resto'roul situé à  Saint-Étienne-le-Laus dans les Hautes Alpes 05"},
            { name: 'og:type', content: "website"}
          ]}
        >
        </Helmet>
        <Layout activeLink="home">
        
        <FocusInExpandFwd loaded={true}>
          <h1 className="site-heading text-center text-white d-block site-heading-lower ml-auto mr-auto">
            Le Relais de l'avance <br /> Traiteur - restaurateur à Saint-Étienne-le-Laus, Hautes Alpes 
          </h1>
        </FocusInExpandFwd>

        <section className="page-section clearfix">
          <div className="container">
            <div className="intro">
              <SlideRightOnAppear loaded={imgLoaded}>
                <img
                  className="intro-img img-fluid mb-3 mb-lg-0 rounded shadow2"
                  src={intro}
                  alt="Presentation Relais de l'Avance"
                />
              </SlideRightOnAppear>
              <SlideLeftOnAppear loaded={imgLoaded}>
                <div className="intro-text left-0 text-center bg-faded p-5 rounded shadow1">
                  <h2 className="section-heading mb-4 section-heading-lower">
                    Le Relais de l'avance
                  </h2>
                  <article className="mb-3">
                    <p>« Véronique et Thierry »</p>
                    <p>Au fil du temps et des prestations, plus que <br/> <Link to="/restaurant-le-relais-de-lavance"><strong>« le Relais »</strong></Link> ou le <strong>« Resto’Roul »</strong>,</p> 
                    <p>ce que les gens retiennent de nos prestations ce sont nos prénoms !</p> 
                    <p>N’est-ce pas là le signe d’une <strong>satisfaction</strong> clientèle ?</p>
                  </article>
                  <div className="intro-button mx-auto">
                    {/* <a className="btn btn-primary btn-xl" href="/#">
                      Visit Us Today!
                    </a> */}
                  </div>
                </div>
              </SlideLeftOnAppear>
            </div>
          </div>
        </section>

        <section className="page-section cta shadow1">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 mx-auto">
              <SlideBottomOnAppear loaded={imgLoaded}>
                <div className="cta-inner text-center rounded shadow1">
                    <h2 className="section-heading mb-4 section-heading-upper">
                      {/* <span className="section-heading-upper">Our Promise</span> */}
                      Le Relais de l’avance : restaurant, épicerie, traiteur pour mariage dans les Hautes Alpes
                    </h2>
                    <article className="mb-0">
                      <p><strong>Le Relais de l’Avance</strong> et un commerce de proximité faisant <Link to="/restaurant-le-relais-de-lavance"><strong>restaurant et Bar</strong></Link> situé dans la <strong>vallée de l’Avance</strong> au sein du département des <span style={{whiteSpace: 'nowrap'}}>hautes alpes (05).</span></p> 
                      <p>En plus de ce commerce, nous avons constitué un concept de <span style={{whiteSpace: 'nowrap'}}><strong>restauration</strong> / <Link to="/traiteur-mariage-hautes-alpes"><strong>traiteur</strong></Link></span> particulier le <Link to="/traiteur-mariage-hautes-alpes"><strong>« Resto’Roul »</strong></Link>.<br/>
                      Mis au point en 2011 et qui se situe entre Food truck et restaurant à domicile et traiteur.</p>
                    </article>
                </div>
              </SlideBottomOnAppear>
              </div>
            </div>
          </div>
        </section>

        <section className="page-section cta shadow1">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 mx-auto">
                <SlideBottomOnAppear loaded={imgLoaded}>
                  <div className="cta-inner text-center rounded shadow1">
                    <h2 className="section-heading mb-4 section-heading-upper">
                      {/* <span className="section-heading-upper">Our Promise</span> */}
                      Traiteur pour mariage dans les Hautes Alpes avec le concept resto’roul
                    </h2>
                    <article className="mb-0">
                      <p>Ce concept est une caravane transformée en <strong>cuisine</strong> qui nous permet de cuisiner sur place et de partout.</p> 
                      <p>Cette cuisine mobile nous permet de mener au mieux notre métier de <strong>restaurateur</strong> et nous tenons à cette appellation qui est notre différence.</p> 
                      <p>C’est donc un chef de cuisine et sa cuisine qui se déplace sur vos évènements.</p> 
                      <p>C’est sans conteste le plus de ce concept car outre le fait de pouvoir cuisiner partout, nous gardons à chaque instant la maîtrise des cuissons, de la chaîne de froid, de l’environnement, un matériel important toujours à portée de main.</p>
                      <p>Découvrez notre concept de <Link to="/traiteur-mariage-hautes-alpes">traiteur pour mariage</Link> mobile grâce à notre “resto’roul"</p>
                    </article>
                  </div>
                </SlideBottomOnAppear>
              </div>
            </div>
          </div>
        </section>
    
    </Layout>
    </>
)}

export default IndexPage;
